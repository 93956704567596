/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        console.log( 'Buenas noches, Panamá' );
        $(".j-fitVids").fitVids();

        var $grid = $('.grid').masonry({
          // options...
          itemSelector: '.grid-item',
          columnWidth: '.grid-sizer',
          percentPosition: true,
          horizontalOrder: true
        });

        var loader = new PxLoader(),
            $images = $('.grid-item .thumbnail-image');

        var temp_src;
        $images.each(function(index, element) {
          temp_src = $(element).attr('src');
          loader.addImage(temp_src);
        });

        // callback that will be run once images are ready
        loader.addProgressListener(function() {
          $grid.masonry();
        });

        // callback that will be run once images are ready
        loader.addCompletionListener(function() {
          $grid.masonry();
        });

        // begin downloading images
        loader.start();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about': {
      init: function() {
        // JavaScript to be fired on the about us page

        var follow_r = function(event) {
          var element = $(event.currentTarget).data('src'),
              img_w = $(event.currentTarget).data('width'),
              img_h = $(event.currentTarget).data('heigth'),
              img = $('<img>', { id: "featured-image", class: 'featured-image', src: element, width: img_w, heigth: img_h});
          $(document).mousemove(function(e) {
            img.offset({
                left: e.pageX - img_w,
                top: e.pageY - img_h - 20
            });
          });
          $(event.currentTarget).append(img);
        };

        var follow_c = function(event) {
          var element = $(event.currentTarget).data('src'),
              img_w = $(event.currentTarget).data('width'),
              img_h = $(event.currentTarget).data('heigth'),
              img = $('<img>', { id: "featured-image", class: 'featured-image', src: element, width: img_w, heigth: img_h}),
              def_w = img_w/2;
          $(document).mousemove(function(e) {
            img.offset({
                left: e.pageX - def_w,
                top: e.pageY - img_h - 20
            });
          });
          $(event.currentTarget).append(img);
        };

        var follow_l = function(event) {
          var element = $(event.currentTarget).data('src'),
              img_w = $(event.currentTarget).data('width'),
              img_h = $(event.currentTarget).data('heigth'),
              img = $('<img>', { id: "featured-image", class: 'featured-image', src: element, width: img_w, heigth: img_h});
          $(document).mousemove(function(e) {
            img.offset({
                left: e.pageX,
                top: e.pageY - img_h - 20
            });
          });
          $(event.currentTarget).append(img);
        };

        $('.features-r-column .feature')
          .on('mouseenter', follow_r)
          .on('mouseleave', function(event){
            $('this').off('mouseenter', follow_r);
            $('img#featured-image').remove();
          });
        $('.features-c-column .feature')
          .on('mouseenter', follow_c)
          .on('mouseleave', function(event){
            $('this').off('mouseenter', follow_c);
            $('img#featured-image').remove();
          });
        $('.features-l-column .feature')
          .on('mouseenter', follow_l)
          .on('mouseleave', function(event){
            $('this').off('mouseenter', follow_l);
            $('img#featured-image').remove();
          });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
